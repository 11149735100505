.font
{
    font-size:larger;
    color: darkblue;
    font-family:fantasy;
    font-style: normal;
    
}

.parent {
  display: flex;
  height: 120px;
  padding: 10px;
  box-sizing: border-box;
  width:100%;
}

.child {
  flex: 1;
  
}
p{
  color:rgba(0,70,130);
}
.brand
{
  justify-content: flex-start;
  text-decoration: none;
  font-size:20px;
  color: rgb(0, 0, 71);
  font-family:fantasy;
  font-style:normal;
}
.nav
{
  
  justify-content: flex-end;
  text-decoration: none;
  font-size:20px;
  color:rgba(0,70,130);
  font-family:fantasy;
  font-style:normal;
  margin-right: 30px;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.scroll-to-top
{
  
  position: fixed;
  bottom: 0px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  color: black;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
  z-index: 1;
}


li,Link {
  display: inline;
  margin-left: 15px;
  margin-right: 15px;
  text-decoration: none;
  color:rgba(0,70,130);
  font-family:fantasy;
  font-size:2vmax;
  
}

@media only screen and (max-width: 600px) {

  li,Link {

    display: inline;
    margin-left: 5px;
    margin-right: 5px;
    text-decoration: none;
    color:rgba(0,70,130);
    font-family:fantasy;
    font-size:2vmax;
  }
 
}

.home-container
{
  
  color:rgba(4, 13, 116, 0.712);
}
/* .service-container
{
  background-color:darkgray;
  color: rgba(4, 13, 116, 0.712);;
  margin-top:100px;
  padding-top: 100px; 
} */
.About-container
{
  background-color:black; 
}

button
{
  text-transform: uppercase;
  color:rgba(0,70,130);
  align-content: center;
  background: white;
  margin-top: 2em;
  border-radius: 30px;
  padding: 0.8em 1em;
  margin-top: 1em;
  opacity: 0.9;
  border-color: rgba(4, 13, 116, 0.712);
  cursor: pointer;
  font-family:fantasy;
  font-size: 15px;
  font:bolder

}
.space
{
  margin-left: 10px;
  margin-right: 10px;
}
.social-media
{
  
 
  padding-bottom: 10px;
  bottom: 0;
  right: 0;
  color: white;
  
}
h1
{
  color:rgba(0,70,130);
font-family: fantasy;
  font-size:5.5vmax;
}
.-linknav
{
  color:rgba(0,70,130);
}
.ourservice
{
    font-size: larger;
    color:  rgba(4, 13, 116, 0.712);
    font-family:fantasy;
    
}
.contact
{
  color:rgba(0,70,130);
    color: white;
    font-weight:bolder;
    font-family:fantasy;
}

myfooter-contacts
{
  left: 0;
}